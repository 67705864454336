.countryselect {
    padding: 25px 21px 25px;
    text-align: right;
}
.flag {
    top: 3px;
    width: 45px;
}

.countryList {
    height: 220px;
    overflow-x: hidden;
    margin: 0;
    list-style: none;
    padding: 10px 30px;
}

.dropdown_list {
    padding: 0 20px;
}
.dropdown_list .dropdown {
    /*background: #fff;*/
    /*border-radius: 5px;*/
    /*padding: 10px 20px;*/
    cursor: pointer;
    /*float: right;*/
}

.toggle {
    width: 100%;
}

.isLoading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.isLoading i {
    font-size: 50px;
    color: #fff;
    animation: round 2s linear infinite;
}

.player {
    width: 100%;
    background: #fff;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
}

.dropdown-menu {
    min-width: 7rem;
}


@keyframes round {
    0% {
        transform: rotate(-360deg);
    }
}