

#question {
    /*background-color: #f56f18;*/
    color: white;
    padding: 0px;
    text-align: left;
    position: absolute;
    top: 35px;
}

#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: justify;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 4px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    position: relative;
    top: 180px;
}

#answers li {
    background-color: #fff;
    border: 2px solid #ffffff;
    min-height: 45px;
    width: 48%;
    display: flex;
    margin-bottom: 22px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
    border-radius: 50px;
}

#answers li span {
    /*background-color: #f56f18;*/
    color: #fff;
    /*font-size: 30px;*/
    flex: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #0b0e21;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px);
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    transform: scale(1.03);
}

#answers li.right {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.right span {*/
/*    background-color: #ffffff;*/
/*}*/

#answers li.right p {
    color: #ffffff;
}

#answers li.wrong {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.wrong span {*/
/*    background-color: #dc3545;*/
/*}*/

#answers li.wrong p {
    color: #ffffff;
}

#submit {
    text-align: center;
    margin: 0px 0;
    position: relative;
    bottom: -165px;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 0;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #f56f18;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px auto;
}

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}
footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 0px;
        padding-right: 10px;
    }

    #answers li {
        width: 100%;
    }

    #submit button {
        /*width: 50%;*/
        /*font-size: 20px;*/
        /*font-weight: 600;*/
        cursor: pointer;
    }
}
@media (max-width: 480px) {

    footer {
        padding: 5px;
    }

    footer small {
        display: none;
    }
}