.dLogo {
    padding: 20px 0 25px;
    text-align: center;
}
.dLogo img {
    max-width: 100px;
    width: 100%;
    margin: 0 auto;
}

@media (max-width: 320px) {
    .mbContainer .singleTv .itemImg {
        width: 50px;
        height: 50px;
    }
}