.quoteItem {
    position: relative;
}

.quoteItem .shareIcon {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 14px;
    color: #999;
}
.quoteItem {
    margin-bottom: 10px;
}
.quoteItem .shareIcon {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 14px;
    color: #999;
}

.quItemInner {
    background: #fff;
    display: block;
    padding: 10px;
    border-radius: 10px;
}

/*.quItemInner img {*/
/*    max-width: 80px;*/
/*    width: 100%;*/
/*    height: 65px;*/
/*    border-radius: 8px;*/
/*}*/

.quoitemInnerWrap {
}

.quoitemInnerWrap .info {
    margin: 0;
    padding: 0 0 0 10px;
}

.quoitemInnerWrap .info p {
    font-size: 12px;
    color: #00a;
    margin: 5px;
}

.quoitemInnerWrap .info .qtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: medium;
    text-align: justify;
    color: #000000;
}
.quoitemInnerWrap .info .views {
    font-size: 12px;
    color: #666;
    text-align: left;
    letter-spacing: 0.5px;
}
