.single_movie_title {
    font-weight: 500;
    line-height: 2.4rem;
    color: black
}
.single_movie_title_img {
    height: 33px;
    width: 45px;
    padding: 0px 5px 0px 0px;
}
.single_movie_views {
    font-size: 14px;
    font-weight: 300;
    color: #666;
    text-align: left;
    letter-spacing: 0.5px;
}
.related_videos {
    font-weight: 300;
    line-height: 2.4rem;
    color: black;
}
.player_video{
    text-align: center;
    position: fixed;
    top: -65px;
    left: -32px;
    right: -167px;
}

/*div_css*/
.movieItemInner {
    background: #fff;
    display: block;
    padding: 10px;
    border-radius: 10px;
}

.movieItemInner img {
    max-width: 80px;
    width: 100%;
    height: 65px;
    border-radius: 8px;
}